import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { Head } from './head';
import { Content } from './content';

const GlobalNetwork: React.FC = () => (
    <ApolloProvider client={GraphQlClient}>
        <>
            <Head />
            <Content />
        </>
    </ApolloProvider>
);

export default GlobalNetwork;
